import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { useDispatch, useSelector } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { DataListHocForDevicesTable } from '../../../DataList';
import { UPGRADE_TO_UNLOCK_POPUP, openModalWindow } from '../../../ModalWindow/slice';
import i18n from '../../../../i18n';
import columns from '../../columns';
import { ADDITIONAL_COST_MODAL_ID } from '../../../../modals';
import CarChargers from './CarChargers';
import { ADD_CAR_MODAL } from './constants';
import { APP_ID, ASKOMA_APP_ID, CKW_APP_ID, SOLAR_MANAGER_APP_ID } from '../../../../config';
import { useCarWebviewCodeMutation } from '../../services';
import { Preloader } from '../../../../components';
import { addNotification } from '../../../NotificationGenerator/slice';
import { getSignInUser_IdSelector } from '../../../../redux-store/selectors/signIn';
import { READ_ONLY_ROLES } from '../../../../constants';

const NoDataComponentRender = () => (
  <div
    className="rt-noData customNoData"
    style={{
      top: '70%'
    }}
  >
    {i18n.t('noRowsFound')}
  </div>
);

/**
 * @module DevicesTable
 * @memberof module:DevicesTable
 */
const CarsTab = (props) => {
  const {
    transformResponse,
    defaultParams,
    devicesUrl,
    myRoleType,
    userId,
    total,
    widthSetting,
    history,
    location,
    isReadOnlyPermission,
    license
  } = props;
  const dispatch = useDispatch();
  const isReadOnlyRole = READ_ONLY_ROLES.includes(myRoleType);
  const savedCar = JSON.parse(localStorage.getItem('car'));
  const signInUserId = useSelector(getSignInUser_IdSelector);
  const { search, pathname } = location;
  const params = new URLSearchParams(search);
  const issuer = params.get('issuer');
  const code = params.get('code');
  const [
    carWebviewCode,
    {
      error: errorCarWebviewCode,
      data: carWebviewCodeData,
      isLoading: isLoadingCarWebviewCode
    }
  ] = useCarWebviewCodeMutation();
  useEffect(() => {
    if (savedCar && issuer && code) {
      carWebviewCode({
        data: {
          linkId: savedCar.linkId,
          url: `${savedCar.redirectUrl}?code=${code}&issuer=${issuer}`,
          brand: savedCar.selectedBrand.value,
          userId: userId === 'my' ? signInUserId : userId
        }
      });

      history.replace(pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (carWebviewCodeData) {
      dispatch(
        openModalWindow({
          modalID: ADD_CAR_MODAL,
          data: {
            selectedBrand: savedCar?.selectedBrand,
            activeTab: 2,
            linkId: carWebviewCodeData.linkId,
            vehicles: carWebviewCodeData.vehicles
          }
        })
      );
      localStorage.removeItem('car');
    }

    if (errorCarWebviewCode) {
      dispatch(addNotification({ type: 'error', text: errorCarWebviewCode.message }));
      localStorage.removeItem('car');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carWebviewCodeData, errorCarWebviewCode]);

  const columnsCars = useMemo(
    () => columns(myRoleType, userId, 'cars', total, isReadOnlyPermission, widthSetting),
    [myRoleType, total, userId, isReadOnlyPermission, widthSetting]
  );

  const addCars = () => {
    if (license?.restrictions?.isCarRestricted) {
      dispatch(openModalWindow({ modalID: UPGRADE_TO_UNLOCK_POPUP }));
    } else {
      if (![SOLAR_MANAGER_APP_ID, ASKOMA_APP_ID, CKW_APP_ID].includes(APP_ID)) {
        dispatch(
          openModalWindow({
            modalID: ADDITIONAL_COST_MODAL_ID,
            data: { translationKey: 'carsAdditionalCostPopUp' }
          })
        );
      }
      dispatch(openModalWindow({ modalID: ADD_CAR_MODAL }));
    }
  };

  const SubComponent = useCallback(
    (v) => <CarChargers myRoleType={myRoleType} rowData={v} isReadOnlyPermission={isReadOnlyPermission} />,
    [myRoleType, isReadOnlyPermission]
  );

  return (
    <>
      {!(isReadOnlyRole || isReadOnlyPermission) && (
        <div className="devices-tab-panel col-auto">
          <a
            onClick={addCars}
            className="is-large-button"
          >
            <span>{i18n.t('addCar')}</span>
          </a>
        </div>
      )}

      <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
        {isLoadingCarWebviewCode ? (
          <div className="admin-preloader-container">
            <Preloader />
          </div>
        ) : (
          <DataListHocForDevicesTable
            tab="cars"
            listID="cars"
            listURL={devicesUrl}
            params={defaultParams}
            transformResponse={transformResponse}
            Component={ReactTable}
            componentProps={{
              NoDataComponent: NoDataComponentRender,
              SubComponent,
              className: '-highlight m-datatable__table',
              freezeWhenExpanded: true,
              columns: columnsCars,
              showPagination: false,
              defaultPageSize: 100,
              sorted: []
            }}
          />
        )}
      </div>
    </>
  );
};

CarsTab.propTypes = {
  myRoleType: PropTypes.string,
  userId: PropTypes.string,
  total: PropTypes.number,
  defaultParams: PropTypes.instanceOf(Object).isRequired,
  devicesUrl: PropTypes.string.isRequired,
  widthSetting: PropTypes.instanceOf(Object),
  transformResponse: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  license: PropTypes.instanceOf(Object).isRequired,
  isReadOnlyPermission: PropTypes.bool.isRequired
};

export default withRouter(CarsTab);
